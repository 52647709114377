@import '../src/scss/themes/theme-dark.scss';
@import '../src/scss/themes/theme-light.scss';
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@200;300&display=swap');

html {
	font-size: 62.5%;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
}

.aligner {
	display: flex;
	align-items: center;
	justify-content: center;
}

.title-container {
	height: 50px;
}

.title-styles {
	font-family: 'Raleway', sans-serif;
	font-size: 250%;
	color: white;
}

.header-icon {
	height: 150px;
	width: 150px;
}

header {
	position: relative;
	height: 520px;
	min-height: 450px;
	width: 100%;
	background-size: cover;
	-webkit-background-size: cover;
	text-align: center;
	overflow: hidden;
	background-color: #242833;
	-webkit-background-size: cover;
	-moz-background-size: cover;
	-o-background-size: cover;
	padding-top: 150px;
	padding-bottom: 0;
}

header::after {
	content: "";
	position:absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	background-size: 125px;
	background-image: url("./frame2.png");
	background-repeat: repeat;
	animation: scroll 1840s linear infinite;
	opacity: .06;
	z-index: 10;
	margin-bottom: 200px;
}
	@keyframes scroll {
		0% {
		background-position: -3000px 3000px;
	}
		12.5% {
		background-position: 0px -3000px;
	}
		25% {
		background-position: -3000px 0px;
	}
		37.5% {
		background-position: 0px 3000px;
	}
		50% {
		background-position: -3000px 3000px;
	}
		62.5% {
		background-position: 0px -3000px;
	}
		75% {
		background-position: -3000px 0px;
	}
		87.5% {
		background-position: 0px 3000px;
	}
		100% {
		background-position: -3000px 3000px;
	}
}

.language-icon {
	font-size: 50px;
	cursor: pointer;
}

.project-date {
	font-size: 16px;
	font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
	padding: 8px 15px 2px 15px;
	position: relative;
	z-index: -500;
	border-top: 5px solid #696969;
	border-radius: 0 0 5px 5px;
	background-color: #696969;
	color: white;
}

.skills-tile {
	background-color: transparentize(#F8F5F4, 0.95);
	padding: 10px 10px 5px 10px;
	width: 100px;
	margin: 5px 0 5px 0;
	border-radius: 8px;
}

.language {
	font-size: 25px;
	background-color: #364780;
	padding-bottom: 10px;
	padding-top: 80px;
}

header h1 {
	font-size: 400%;
	text-align: center;
	font-weight: 600 !important;
	color: #353239;
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	margin: 0 auto 18px auto;
	width: 100%;
}

.slider-image {
	border: 5px solid #4d70b1;
}

.slider-tab {
	background-color: #8890d8;
	height: 25px;
}

.slider-iconfiy {
	margin-top: 10px;
}

.styles_typicalWrapper__1_Uvh::after {
	cursor: none !important;
	display: none;
}

#about {
	background: #cecece;
	overflow: hidden;
}

#about h1 {
	padding-top: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: #fffdfd;
	text-align: center;
}

.foto img {
	display: block;
	width: 100%;
}

.foto div {
	background: #cecece;
	display: inline-block;
	margin: 0 auto 5% auto;
	padding: 10px 10px 5px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.foto div:after {
	color: #333;
	font-size: 25px;
	content: attr(title);
	position: relative;
	top: 15px;
}

.foto div:hover {
	-webkit-transform: scale(1.01);
	-moz-transform: scale(1.01);
	transform: scale(1.01);
	z-index: 10;
	-webkit-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	-moz-box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
	box-shadow: 0 10px 20px rgba(0, 0, 0, .7);
}

.project-title-settings {
	margin-top: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: black;
	text-align: center;
}

.polaroid img {
	display: block;
	max-width: 200px;
}

.polaroid span {
	background: #cecece;
	display: inline-block;
	margin: 55px 75px 30px;
	padding: 15px 15px 30px;
	text-align: center;
	text-decoration: none;
	-webkit-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-moz-box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	box-shadow: 0 4px 6px rgba(0, 0, 0, .3);
	-webkit-transition: all .20s linear;
	-moz-transition: all .20s linear;
	transition: all .20s linear;
	z-index: 0;
	position: relative;
}

.link-href {
	color: black;
}

.wave {
	font-size: 160%;
}

.font-trebuchet {
	font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

#preview {
	width: 500px;
	background-color: #817ea1;
	padding: 15px;
	position: relative;
	margin-bottom: 15%;
}

.center {
	display: flex;
	justify-content: center;
	align-items: center;
}

#resume {
	background: #364780;
}

.experience-icon {
	font-size: 300%;
	margin-top: 25%;
	text-align: center;
}

.main-badge {
	font-size: 13px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #8098bb !important;
	color: white;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.experience-badge {
	font-size: 11px !important;
	text-align: left !important;
	padding: 5px 8px 5px 8px !important;
	vertical-align: baseline;
	background-color: #b8cae4 !important;
	color: black;
	font-weight: lighter !important;
	font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
}

.vertical-timeline-element-date {
	padding: 0 !important;
	margin: 0 !important;
	color: white;
}

@media only screen and (max-width: 1170px) {
	.experience-icon {
		font-size: 170%;
		margin-top: 27%;
		text-align: center;
	}
}

.modal-inside .modal-content {
	background: white;
}

.bars {
	width: 95%;
	float: left;
	padding: 0;
	text-align: left;
}

.bars .skills {
	margin-top: 36px;
	list-style: none;
}

.bars li {
	position: relative;
	margin-bottom: 60px;
	background: #ccc;
	height: 42px;
	border-radius: 3px;
}

.bars li em {
	font: 15px 'opensans-bold', sans-serif;
	color: #313131;
	text-transform: uppercase;
	letter-spacing: 2px;
	font-weight: normal;
	position: relative;
	top: -36px;
}

.bar-expand {
	position: absolute;
	left: 0;
	top: 0;
	margin: 0;
	padding-right: 24px;
	background: #313131;
	display: inline-block;
	height: 42px;
	line-height: 42px;
	border-radius: 3px 0 0 3px;
}

.modal-close {
	text-align: right;
	padding: 10px 15px 10px 15px;
	cursor: pointer;
}

.close-icon {
	color: black;
	font-weight: lighter !important;
}

.modal-description {
	text-align: justify;
	padding: 5px 5px 0 5px;
	margin-bottom: 20px;
	font-size: 12px;
}

.awssld__next {
	outline: none !important;
}

.awssld__prev {
	outline: none !important;
}

.loader-bar-color {
	color: black !important;
}

#portfolio {
	background: #364780;
	padding-bottom: 5%;
}

.portfolio-item {
	max-width: 100%;
	margin-bottom: 15px;
	text-align: center;
}

.portfolio .portfolio-item .portfolio-item-caption {
	-webkit-transition: all ease 0.5s;
	-moz-transition: all ease 0.5s;
	transition: all ease 0.5s;
	opacity: 0;
	background-color: rgba(51, 51, 51, 0.9);
}

.portfolio .portfolio-item .portfolio-item-caption:hover {
	opacity: 1;
}

.portfolio .portfolio-item .portfolio-item-caption .portfolio-item-caption-content {
	font-size: 1.5rem;
}

@media (min-width: 576px) {
	.portfolio .closeButtonResponsive {
		display: block;
	}
	.portfolio .portfolio-item {
		margin-bottom: 30px;
	}
}

#skills {
	background: #1F1F1F;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 10%;
}

.section-title {
	padding-top: 5%;
	padding-bottom: 5%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: #ffffff;
	text-align: center;
}

.skill-icon {
	font-size: 180%;
	color: white;
	text-align: center;
	position: relative;
}

footer {
	background: #1F1F1F;

	width: 100%;
	overflow: hidden;
	font-size: 14px;
	color: white;
	position: relative;
	text-align: center;
}

footer a,
footer a:visited {
	color: #fff;
}

footer a:hover,
footer a:focus {
	color: #fff;
}

.social-links {
	margin-top: 50px;
	font-size: 22px;
	z-index: 900;
}

.back-to-top {
	height: 65px;
	position: fixed;
	right: 2rem;
	bottom: 2rem;
	cursor: pointer;
	// opacity: 100%;
	// transition: opacity 0.5s;
}

// .hidden {
// 	opacity: 0%;
// }

// .back-to-top:hover {
// 	opacity: 60%;
// }

// .lightswitch {
// 	height: 65px;
// 	position: fixed;
// 	right: 2rem;
// 	top: 2rem;
// 	cursor: pointer;
// 	z-index: 1000;
// }

.topnav {
	height: 65px;
	position: fixed;
	top: 2rem;
	cursor: pointer;
	z-index: 1000;
	margin-left: 40px;
}

.logo {
	height: 35px;
	z-index: -90;
}

.navbutton {
	height: 35px;
	z-index: 1000;
}

.hide {
	opacity: 80%;
	transition: opacity 0.5s;
}

.hide:hover {
	opacity: 100%;
}

.none {
	display: none;
}

.cover {
	position: fixed;
	top: 0rem;
	cursor: pointer;
	z-index: 950;
	background-color: rgba(0, 0, 0, 0.863);
	height: 100vh;
	width: 100vw;
	transition: 0.3s ease-in-out;
}

.centered {
	z-index: 1000;
	color: white;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100%;
}

#contact {
	background: #1F1F1F;
	min-height: 200px;
	width: 100%;
	overflow: hidden;
	padding-bottom: 35px;
}

.form-title-settings {
	margin-top: 4%;
	font: 18px/24px 'opensans-bold', sans-serif;
	text-transform: uppercase;
	letter-spacing: 3px;
	color: rgb(255, 255, 255);
	text-align: center;
}

.input {
	height: 38px;
	width: 320px;
	font-size: 20px;
}

.textarea {
	height: 380px;
	width: 320px;
	font-size: 20px;
}

.button {
	height: 38px;
	width: 200px;
	text-align: center;
	margin-top: 30px;
	margin-bottom: auto;
	border: 2px solid white;
	background: #1F1F1F;
	text-align: center;
	border-radius: 23px;
	cursor: pointer;
	font: 18px/24px 'opensans-bold', sans-serif;
	color: #ffffff;
}

.flex {
	display: flex;
	align-items: center;
	flex-direction: column;
}

.lightbutton {
	height: 45px;
	z-index: 1000;
	position: fixed;
	right: 2rem;
	top: 2rem;
	cursor: pointer;
}
